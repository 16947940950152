import { useState, useEffect } from 'react'
import axios from "../api/axios"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { format } from 'date-fns'
// import { faCommentAlt } from '@fortawesome/free-regular-svg-icons'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBold, faPerson } from '@fortawesome/free-solid-svg-icons'
import { faAirbnb } from '@fortawesome/free-brands-svg-icons'


const BrokerIcon = ({ item }) => {
    return <span className="broker">{
        item.is_airbnb
            ? <a href={`https://www.airbnb.fr/hosting/reservations/details/${item.airbnb_code}`} target={`_details_${item.airbnb_code}`}><FontAwesomeIcon icon={faAirbnb} color="#FF5A5F" />{item.airbnb_code}</a>
            : ""
    }
    {
        item.is_booking
        ? (item.booking_id 
            ? <a href={`https://admin.booking.com/hotel/hoteladmin/extranet_ng/manage/booking.html?res_id=${item.booking_id}&hotel_id=${property ? property.booking_id : item.property.booking_id}&lang=fr`} target={`_details_${item.booking_code}`}><FontAwesomeIcon icon={faBold} style={{ color: '#003b95' }} /></a> 
            : <a href={`https://admin.booking.com/`}><FontAwesomeIcon icon={faBold} style={{ color: 'grey' }} /></a>
            ) 
        : ""
    }
    {
        item.for_owner ?
        <FontAwesomeIcon icon={faPerson} />
        : ""
    }</span>
}

export default () => {

    const {month = 6, year = 2024} = useParams()

    const [analysis, setAnalysis] = useState()

    const fetchTodo = async () => {
        try {
            const response = await axios().get(`/versement/analysis/${year}/${month}`)
            setAnalysis(response.data)
        } catch (err) {
            console.log(err)
        }
    }


    useEffect(() => {

        fetchTodo()

    },[])

    return (
        <article>
            {analysis?.length
                ? (
                    <table>
                        <thead>
                            <tr>
                                <th>Logement</th>
                                <th>Voyage</th>
                                <th>Voyageur</th>
                                <th>Manque</th>
                                <th>Prix (en base)</th>
                                <th>Prix (calculé)</th>
                                <th>Reçu par Airbnb</th>
                            </tr>
                        </thead>
                        <tbody>
                            {analysis.map((item, i) =>
                                <tr key={i}>
                                    {/* <td>{item.id}</td> */}
                                    <td>{item.p_name}</td>
                                    <td><BrokerIcon item={item} /></td>
                                    <td>{item.traveler}</td>
                                    <td>{item.diff_amount?.toFixed(2)} €</td>
                                    <td>{item.price?.toFixed(2)} €</td>
                                    <td>{item.r_amount?.toFixed(2)} €</td>
                                    <td>{item.v_amount?.toFixed(2)} €</td>
                                    {/* <td>{item.propertyId}</td>
                                    <td>{format(new Date(item.dt_to), 'dd/MM/yyyy')}</td>
                                    <td>{item.v_code}</td> */}
                                </tr>
                            )}
                        </tbody>
                    </table>
                )
                : (
                    <p>Rien...</p>
                )}        
        </article>
    )
}