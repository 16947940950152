import axios from "../api/axios"

export async function getNextCheckin (count) {

    try {
        const { data } = await axios().get(`/reservations/next-checkin/${count}`)
        return data
    } catch (error) {
        if (error.response) {
            let { status, statusText } = error.response;
            throw Error(statusText);
        } else {
            throw Error("Perte de connexion");
        }
    }
}

export async function getNextCheckout(count) {
    try {
        const { data } = await axios().get(`/reservations/next-checkout/${count}`)
        return data
    } catch (error) {
        if (error.response) {
            let { status, statusText } = error.response;
            console.log(status, statusText);
        } else {
            console.log("Perte de connexion");
        }
        return []
    }
}

export async function searchReservation(pattern) {
    try {
        const { data } = await axios().get(`/reservations/search/${pattern}?withProperty=1`)
        return data
    } catch (error) {
        if (error.response) {
            let { status, statusText } = error.response;
            console.log(status, statusText);
        } else {
            console.log("Perte de connexion");
        }
        return []
    }
}

export async function getReservationToBeValidated() {
    try {
        const { data } = await axios().get(`/reservations/to-be-updated?withProperty=1`)
        return data
    } catch (error) {
        if (error.response) {
            let { status, statusText } = error.response;
            console.log(status, statusText);
        } else {
            console.log("Perte de connexion");
        }
        return []
    }
}


export async function getReservationsInProgress(count) {
    try {
        const { data } = await axios().get(`/reservations/in-progress`)
        return data
    } catch (error) {
        if (error.response) {
            let { status, statusText } = error.response;
            console.log(status, statusText);
        } else {
            console.log("Perte de connexion");
        }
        return []
    }
}

export async function getReservation(id){
    try{
        const {data} = await axios().get(`/reservations/${id}?withProperty=1&withHousekeeper=1`)
        return data
    } catch (error) {
        if (error.response) {
            let { status, statusText } = error.response;
            console.log(status, statusText);
        } else {
            console.log("Perte de connexion");
        }
        return {}
    }
}

export async function getReservationNextTo(id){
    try{
        const {data} = await axios().get(`/reservations/next-to/${id}`)
        return data
    } catch (error) {
        if (error.response) {
            let { status, statusText } = error.response;
            console.log(status, statusText);
        } else {
            console.log("Perte de connexion");
        }
        return {}
    }
}

export async function updateReservation(updatedReservation){
    try{
        await axios().patch(`/reservations/${updatedReservation.id}`, updatedReservation, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
    } catch (error) {
        if (error.response) {
            let { status, statusText } = error.response;
            console.log(status, statusText);
        } else {
            console.log("Perte de connexion");
        }
    }
}

export async function getReservationMessage (resaId) {

    try {
        const { data } = await axios().get(`/reservations/message/${resaId}`)
        //co
        return data
    } catch (error) {
        if (error.response) {
            console.log(error)
            let { data } = error.response;
            throw Error(data);
        } else {
            throw Error("Perte de connexion");
        }
    }
}

// export async function sendSmsReservation(id, message){
//     try{
//         const {data} = await axios().post(`/reservation/${id}/sms`, {message}, {
//             headers: {
//               'Content-Type': 'application/json'
//             }
//           })
//         console.log(data)
//     } catch (error) {
//         if (error.response) {
//             let { status, statusText } = error.response;
//             console.log(status, statusText);
//         } else {
//             console.log("Perte de connexion");
//         }
//     }
// }

export async function setReservationCode(id) {
    try {
        const { data } = await axios().post(`/reservations/${id}/code`)
        console.log("REST", data)
        return data
    } catch (error) {
        if (error.response) {
            let { status, statusText } = error.response;
            console.log(status, statusText);
            return {error:1}
        } else {
            console.log("Perte de connexion");
        }
        return {}
    }
}

// export async function getProperty(id){
//     try{
//         const {data} = await axios().get(`/properties/${id}`)
//         return data
//     } catch (error) {
//         if (error.response) {
//             let { status, statusText } = error.response;
//             console.log(status, statusText);
//         } else {
//             console.log("Perte de connexion");
//         }
//         return {}
//     }
// }


export async function updateProperty(updatedProperty){
    try{
        await axios().put(`/properties/${updatedProperty.id}`, updatedProperty)
    } catch (error) {
        if (error.response) {
            let { status, statusText } = error.response;
            throw Error(statusText);
        } else {
            throw Error("Perte de connexion");
        }
    }
}

// export async function getPropertyDevices(){
//     try{
//         const {data} = await axios().get(`/properties/devices`)
//         return data
//     } catch (error) {
//         if (error.response) {
//             let { status, statusText } = error.response;
//             console.log(status, statusText);
//         } else {
//             console.log("Perte de connexion");
//         }
//         return {}
//     }
// }

export async function getClient(id){
    try{
        const {data} = await axios().get(`/clients/${id}`)
        return data
    } catch (error) {
        if (error.response) {
            let { status, statusText } = error.response;
            console.log(status, statusText);
        } else {
            console.log("Perte de connexion");
        }
        return {}
    }
}

export async function updateClient(updatedClient){
    try{
        await axios().put(`/clients/${updatedClient.id}`, updatedClient)
    } catch (error) {
        if (error.response) {
            let { status, statusText } = error.response;
            console.log(status, statusText);
        } else {
            console.log("Perte de connexion");
        }
    }
}

export async function getHouseKeepers(){
    try{
        const {data} = await axios().get(`/housekeepers`)
        return data
    } catch (error) {
        if (error.response) {
            let { status, statusText } = error.response;
            console.log(status, statusText);
        } else {
            console.log("Perte de connexion");
        }
        return []
    }
}

export async function getHouseKeeper(id){
    try{
        const {data} = await axios().get(`/housekeepers/${id}`)
        return data
    } catch (error) {
        if (error.response) {
            let { status, statusText } = error.response;
            console.log(status, statusText);
        } else {
            console.log("Perte de connexion");
        }
        return {}
    }
}

export async function sendSms(number, message) {
    try {
        await axios().post('/sms',
            {
                number: number,
                message: message
            }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    } catch (error) {
        if (error.response) {
            let { status, statusText } = error.response;
            window.alert(status, statusText);
        }
    }
}

export async function unlockOpener(resaId) {
    try {
        await axios().post(`/reservation/${resaId}/unlock_opener`,
            {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    } catch (error) {
        if (error.response) {
            let { status, statusText } = error.response;
            console.log(status, statusText);
        }
    }
}