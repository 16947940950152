import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "../api/axios"
import { BallTriangle } from "react-loader-spinner"
import { Navigate } from 'react-router-dom';

const Loading = () => <BallTriangle
    height={100}
    width={100}
    radius={5}
    color="#4fa94d"
    ariaLabel="ball-triangle-loading"
    wrapperClass={{}}
    wrapperStyle=""
    visible={true}
/>

function PropertyEntries() {

    const [property, setProperty] = useState({ok:false, loading:true})
    const params = useParams() || {} ;
    const id = params.id || undefined;

    useEffect(() => {
        async function fetchProperty() {
            try {
                const response = await axios().get(`/properties/${id}?withEntries=1`)
                const p = {  ...response.data, ok:true, loading:false }
                setProperty(p)
            } catch (error) {
                if (error.response) {
                    let { status, statusText } = error.response;
                    if (status == 404) {
                        setProperty({ok:false, loading:false})
                    } else {
                        setProperty({ok:false, loading:true})
                        console.log(status, statusText);
                    }
                } else {
                    setProperty({ok:false, loading:true})
                    // console.log(error);
                }
            }
        }
        fetchProperty()
    }, []);

    return property.loading
        ? <Loading />
        : !property.ok
            ? <Navigate to="/unauthorized" replace={true}/>
            :
            <>
                <h1>Logement {property.name}</h1>
                <ul>
                    {property.entries.map((element, i) => {
                        let txt = ""
                        if (element.type == 0) {
                            txt = "Statique - "
                        } else if (element.type == 1) {
                            txt = "Smartlock - "
                        } else if (element.type == 2) {
                            txt = "Opener - "
                        } else if (element.type == 3) {
                            txt = "Igloohome - "
                        }
                        txt += element.location + " : " + element.value

                        return <li key={i}>{txt}</li>
                    })}
                </ul>
            </>



}

export default PropertyEntries;
