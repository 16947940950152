import SubNavbarLayout from "../components/subnavbarlayout/SubNavbarLayout";

export default () => {

    const tabs = [
        { path: '/private', label: 'Todo quotidienne' },
        { path: '/private/devices', label: 'Etat Nuki' },
        { path: '/private/sms', label: 'SMS' },
        { path: '/private/csv-deposit', label: 'Déposer le CSV' },
        { path: '/private/versement-analysis', label: 'Analyse des versements' }
    ]

    return <SubNavbarLayout tabs={tabs} title="Privé" />
}