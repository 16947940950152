import { searchReservation } from "../utils/RestLocem"

import ChekinTable from "../components/checkin/ChekinTable";

function CheckinSearch() {

    const loadNextCheckout = async (pattern) => {
        return await searchReservation(pattern)
    }

    return (
        <ChekinTable search loadReservations={(pattern) => { return loadNextCheckout(pattern) }} />
    );
}

export default CheckinSearch;