import { useState, useEffect } from 'react'
import axios from "../api/axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format } from 'date-fns'
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons'

const PrivateTodo = () => {

    const [todo, setTodo] = useState()

    const fetchTodo = async () => {
        try {
            const response = await axios().get('/private/todo')
            setTodo(response.data)
        } catch (err) {
            console.log(err)
        }
    }


    useEffect(() => {

        fetchTodo()

    },[])

    return (
        <article>
            {todo?.length
                ? (
                    <table>
                        <thead>
                            <tr>
                                <th>E/S</th>
                                <th>Date</th>
                                <th>Voyageur</th>
                                <th>Propriété</th>
                            </tr>
                        </thead>
                        <tbody>
                            {todo.map((item, i) =>
                                <tr key={i}>
                                    <td>{item?.label}</td>
                                    <td>{format(new Date(item?.dt), 'dd/MM/yyyy')}</td>
                                    <td>{item?.traveler}
                                    &nbsp;{ item.is_airbnb && item.message_id ? <a href={`https://www.airbnb.fr/hosting/messages/${item.message_id}`} target={`_inbox_${item.message_id}`}><FontAwesomeIcon icon={faCommentAlt} color="#FF5A5F" /></a> : "" }</td>
                                    <td>{item?.property.name}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )
                : (
                    <p>Rien...</p>
                )}        
        </article>
    )
}

export default PrivateTodo