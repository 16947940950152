import { Link, Navigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import buildDisplayDate from "../utils/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPerson } from "@fortawesome/free-solid-svg-icons"
import axios from "../api/axios";
import { BallTriangle } from "react-loader-spinner";
import AppLink from "../components/display/AppLink";

const Loading = () => <BallTriangle
    height={100}
    width={100}
    radius={5}
    color="#4fa94d"
    ariaLabel="ball-triangle-loading"
    wrapperClass={{}}
    wrapperStyle=""
    visible={true}
/>

export default function Reservation() {

    const [reservation, setReservation] = useState({ok:false, loading:true})
    const params = useParams() || {} ;
    const id = params.id || undefined;



    useEffect(() => {
        async function fetchProperty() {
            try {
                const response = await axios().get(`/reservations/${id}?withProperty=1&withHousekeeper=1`)
                const r = {  ...response.data, ok:true, loading:false }
                r.d_dt_from = buildDisplayDate(r.dt_from)
                r.d_dt_to = buildDisplayDate(r.dt_to)
                r.ca = ((r.price - r.property.houseclean) * r.property.commission / 100 + r.property.houseclean - Math.ceil(r.property.max_travelers / 2) * 13 - r.property.hc_hours * 23).toFixed(2)
                setReservation(r)
            } catch (error) {
                if (error.response) {
                    let { status, statusText } = error.response;
                    if (status == 404) {
                        setReservation({ok:false, loading:false})
                    } else {
                        setReservation({ok:false, loading:true})
                        console.log(status, statusText);
                    }
                } else {
                    setReservation({ok:false, loading:true})
                    // console.log(error);
                }
            }
        }
        fetchProperty()
    }, []);

    return reservation.loading
        ? <Loading />
        : !reservation.ok
            ? <Navigate to="/unauthorized" replace={true} />
            : <>
                <h1>Réservation {reservation.airbnb_code}</h1>
                <ul>
                    <li>Logement : <Link to={'/p/' + reservation.property.id}><strong>{reservation.property.name}</strong></Link></li>
                    <li>Voyageur : <strong>{reservation.traveler}</strong>&nbsp;&nbsp;{[...Array(reservation.travelers_count)].map((e, i) => <FontAwesomeIcon icon={faPerson} />)}</li>
                    <li>Séjour : {reservation.d_dt_from} - {reservation.wished_checkin} =&gt; {reservation.d_dt_to} - {reservation.wished_checkout}</li>
                    <li>Message : <a href={"https://www.airbnb.fr/hosting/messages/" + reservation.message_id} target="_blank">Message</a></li>
                    <li>Téléphone : {reservation.phone} <em>{reservation.suggested_phone}</em></li>
                    <li>Prix : {reservation.price}€ ({reservation.ca}€)</li>
                    <li>Aide-ménagère : {reservation.housekeeper?.name}</li>
                    <li>SSID : {reservation.property.wifi_ssid}</li>
                    <li>Clé WiFi : {reservation.property.wifi_key}</li>
                    <li>Code porte/keybox : {reservation.code}</li>
                    <li>Lien App : <AppLink reservation={reservation}>Lien</AppLink></li>
                </ul>
            </>
    

}
