import React from 'react';
import SubNavbarLayout from "../components/subnavbarlayout/SubNavbarLayout"

function HouseKeeperLayout() {

    const tabs = [
        { path: '/hk', label: 'Mes ménages' },
        { path: '/hk/synthesis', label: 'Synthèses' }
    ]

    return <SubNavbarLayout tabs={tabs} />
}

export default HouseKeeperLayout;