import Popup from "../popup/Popup"
import { unlockOpener } from "../../utils/RestLocem"

function PropertyPopup(props) {

    const handleUnlockOpenerClick = (event, resaId) => {
        unlockOpener(resaId)
    }

    return (
        <Popup trigger={props.trigger} setTrigger={props.setTrigger}>
            {(props.reservation)
                ? (<>
                    <div><strong>{props.reservation.property.name}</strong></div>
                    <div>{props.reservation.property.address}</div>
                    <div>{props.reservation.property.add_comp}</div>
                    <div>{props.reservation.property.cp} {props.reservation.property.city}</div>
                    <div style={{marginTop: "10px"}}><ul>
                    { props.reservation.property.entries.map( (entry, i) => {
                        if (entry.type == 0) {
                            if (props.reservation.property['phone_in_sms']) {
                                return (<li key={i}>{entry.location} : appelez le <a href={"tel:"+props.reservation.property['phone_in_sms']}>{props.reservation.property['phone_in_sms']}</a> pour ouvrir</li>)
                            } else {
                                return (<li key={i}>{entry.location} : {entry.value}</li>)
                            }
                        } else if (entry.type === 1) {
                            return (<li key={i}>{entry.location} : {props.smartlockCode}</li>)
                        } else if (entry.type === 3) {
                            console.log("props.properties:", props.properties);
                            console.log("props.reservation.property.id:", props.reservation.property.id);
                            
                            const [selectedProperty] = props.properties.filter(item => {
                              console.log("item.property:", item.property);
                              return item.property == props.reservation.property.id;
                            });
                            
                            console.log("selectedProperty:", selectedProperty);
                            
                            return (<li key={i}>{entry.location} : {selectedProperty.specific_code ? selectedProperty.specific_code : "Votre code"}</li>)
                        } else if (entry.type === 2) {
                            if (entry.url_in_sms) {
                                return (<li key={i}>{entry.location} : <button onClick={event => handleUnlockOpenerClick(event, props.reservation.id)}>Ouvrir porte</button></li>)
                            } else {
                               return (<li key={i}>{entry.location} : {entry.value}</li>)
                            }
                    }})}
                    </ul></div>
                </>)
                : "Oups..."
            }
        </Popup>
    )

}

export default PropertyPopup;