import React from "react";

import { differenceInDays, format } from 'date-fns'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAirbnb } from "@fortawesome/free-brands-svg-icons";
import { faMobileScreen, faCheck, faHouse, faTriangleExclamation, faCirclePlus, faCircle, faCircleMinus, faPerson, faPaperclip, faCog, faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { faFileLines, faCommentAlt, faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons"
import DateDisplay from "../display/DateDisplay";
import BrokerDisplay from "../display/BrokerDisplay";
import AppLink from "../display/AppLink"
import { fr } from 'date-fns/locale'

const CodesTds = ({ reservation, handleCodeClick }) => {
    if (reservation.property.entries.filter(x => x.type == 1).length > 0) {
        return <>
            <td data-label="Code">{reservation.code}</td>
            <td><FontAwesomeIcon icon={faCog} onClick={(event) => handleCodeClick(event, reservation)} /></td>
        </>
    } else if (reservation.property.entries.filter(x => x.type == 3).length > 0) {
        return <>
            <td colSpan={2} data-label="Code">{reservation.code}</td>
        </>
    } else {
        return <>        <td colSpan={2} style={{background:"lightgrey"}}> </td></>
    }
}


export default function ReadOnlyCheckinRow({ reservation, handleEditClick, handleCodeClick, handleSMSClick, handleUnlockOpenerClick, handleDeleteClick, handleAvailableClick}){

    // if (reservation.property.id == 25) {
    //     console.log(reservation
    //     )
    // }
    const isToday = (date) => {
        const dateDate = new Date(date)
        dateDate.setHours(0,0,0,0)
        const todayDate = new Date()
        todayDate.setHours(0,0,0,0)
        return dateDate.toDateString() == todayDate.toDateString()
    }
    
    const dt_from_today = isToday(reservation.dt_from)
    const dt_to_today = isToday(reservation.dt_to)
    
    let danger = false
    
    const fromDate = new Date(reservation.dt_from)
    fromDate.setHours(0,0,0,0)
    const toDate = new Date(reservation.dt_to)
    toDate.setHours(0,0,0,0)
    let priorDate = new Date()
    if (reservation.prior) {
        priorDate = new Date(reservation.prior.dt_to)
        priorDate.setHours(0,0,0,0)
    }
    const nowDate = new Date()
    nowDate.setHours(0,0,0,0)
    const tomorrowDate = new Date(nowDate)
    tomorrowDate.setDate(tomorrowDate.getDate() + 1)
    
    const className = dt_from_today | dt_to_today ? "today" : "notToday"
    
    const nuit = differenceInDays(new Date(reservation.dt_to), new Date(reservation.dt_from))
    
    if ((!reservation.phone) || (reservation.phone.length < 6) || (reservation.phone.startsWith("+1"))) {
        danger = true
    }
    
    function getFlagEmoji(countryCode) {
        const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map(char => 127397 + char.charCodeAt());
        return String.fromCodePoint(...codePoints);
    }
    
    // const handleAvailable = (event, reservation) => {
        //     // console.log(event)
        //     console.log("handleAvailable", reservation)
        // }
        // const handleDisable = (event, reservation) => {
            //     // console.log(event)
            //     console.log("handleDisable", reservation)
            // }
            
    return (
        <tr className={`${className} ${reservation.tbcreated ? "new" : ""} ${reservation.tbcancelled ? "del" : ""}`}>
            <td>
                {reservation.tbcancelled
                    ? <FontAwesomeIcon icon={faCircleMinus} color="gray" title="Annulé"/>
                    : ""}
                {reservation.tbchanged
                    ? <FontAwesomeIcon icon={faCircle} color="gray" title="Modifié"/>
                    : ""}
                {reservation.tbcreated
                    ? <FontAwesomeIcon icon={faCirclePlus} color="gray" title="Nouveau"/>
                    : " "}
            </td>
            <td><BrokerDisplay reservation={reservation} /></td>
            <td data-label="Arrivée">{dt_from_today ? (<strong><DateDisplay value={reservation?.dt_from} /> {reservation.wished_checkin}</strong>) : <><DateDisplay value={reservation.dt_from} /> {reservation.wished_checkin}</>}</td>
            <td data-label="Départ">{dt_to_today ? (<strong><DateDisplay value={reservation?.dt_to} /> {reservation.wished_checkout}</strong>) : <><DateDisplay value={reservation.dt_to} /> {reservation.wished_checkout}</>}</td>
            <td className="not-media" data-label="Nuits" style={{ textAlign: "center" }}>{nuit} nuit{nuit > 1 ? "s" : ""}</td>
            <td data-label="Logement">
                {reservation?.available
                    ? <FontAwesomeIcon icon={faCheckSquare} title='OK' color='SeaGreen' size="lg" onClick={(event) => handleAvailableClick(event, reservation, 0)} />
                    : <FontAwesomeIcon icon={faSquare} title='A faire' color='Gray' size="lg" onClick={(event) => handleAvailableClick(event, reservation, 1)} />
                }
                &nbsp;
                <Link to={"/p/" + reservation.property.id} ><FontAwesomeIcon icon={faHouse} color={!reservation.prior || reservation.prior?.hc_done ? "SeaGreen" : (priorDate.toDateString() == fromDate.toDateString() ? "Crimson" : "LightSlateGray")} /> {reservation.property.name}</Link>
            </td>
            <td data-label="Voyageur">
                {reservation?.arrived == undefined
                    ? <FontAwesomeIcon icon={faTimesCircle} title='En attente' color='Gray'  />
                    : <FontAwesomeIcon icon={faCheckCircle} title={`Arrivé : ${format(new Date(reservation.arrived), "dd MMMM HH:mm", { locale: fr })}`} color='SeaGreen'  />
                }   
                &nbsp;
                <Link to={"/r/" + reservation.id} >
                    {reservation.traveler}
                </Link>
                &nbsp;&nbsp;
                {[...Array(reservation.travelers_count)].map((e, i) => <FontAwesomeIcon key={`p${i}`} icon={faPerson} />)}
                {reservation.memo && <FontAwesomeIcon icon={faFileLines} color='SeaGreen' title={reservation.memo} />}
                &nbsp;
                {reservation.is_airbnb && reservation.message_id ? <a href={`https://www.airbnb.fr/hosting/messages/${reservation.message_id}`} target={`_inbox_${reservation.message_id}`}><FontAwesomeIcon icon={faCommentAlt} color="#FF5A5F" /></a> : ""}
                &nbsp;
                <AppLink reservation={reservation}><FontAwesomeIcon icon={faPaperclip} /></AppLink>
                
            </td>
            <td data-label="Contact">{ getFlagEmoji(reservation.countryCode) } <Link to={ "tel:" + reservation.phone}>{ reservation.phone}</Link></td>
            <CodesTds reservation={reservation} handleCodeClick={handleCodeClick} />
            <td data-label="Envoi Code">{reservation.comm_via_airbnb == 1 ? (<FontAwesomeIcon icon={faAirbnb} color='#FF5A5F' />) : (<FontAwesomeIcon icon={faMobileScreen}  />) } <button onClick={(event) => handleSMSClick(event, reservation)}>Message</button> {danger ? (<FontAwesomeIcon icon={faTriangleExclamation} title='Attention' color='Crimson' size="lg"/>) : " "}{reservation.sms_sent == 1 ? (<FontAwesomeIcon icon={faCheck} title='OK' color='SeaGreen' size="lg"/>) : " "}</td>
            <td data-label="Actions">
                <input type="button" onClick={(event) => handleEditClick(event, reservation)} value="Modifier" />
                {reservation.tbcancelled ? <input type="button" onClick={(event) => handleDeleteClick(event, reservation)} value="Supprimer" /> : ""}
            </td>
            <td>{ reservation.property.nuki_opener_id ? ( <button onClick={(event) => handleUnlockOpenerClick(event, reservation)}>Ouvrir porte</button> ) : " " }</td>
        </tr>
    )
}