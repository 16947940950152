import { getReservationsInProgress } from "../utils/RestLocem"

import ChekinTable from "../components/checkin/ChekinTable";

function CheckinInProgress() {

    const loadInProgress = async () => {
        return await getReservationsInProgress();
    }

    return (
        <ChekinTable loadReservations={() => { return loadInProgress() }} />
    );
}

export default CheckinInProgress;