import { useState, useEffect } from 'react'
import axios from "../api/axios"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEuroSign,  faHandshake } from "@fortawesome/free-solid-svg-icons";
import { format } from 'date-fns'
import InsideArticle from '../components/article/InsideArticle'


export default () => {

    const [invoices, setInvoices] = useState()
    const [status, setStatus] = useState({ ready: false, ok: false })
    const [searchPattern, setSearchPattern] = useState({})

    const fetchInvoices = async () => {
        try {
            const queryString = Object.keys(searchPattern)
                .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(searchPattern[key].trim()))
                .join('&')

            const response = await axios().get(`/invoices/search?${queryString}`)
            const invoices = response.data.sort((a, b) => a.invoice_no > b.invoice_no)
            setInvoices(invoices)
            setStatus({ ready: true, ok: true })
            // console.log(response.data)
        } catch (err) {
            console.log(err)
        }
    }


    useEffect(() => {

        fetchInvoices()

    },[])


    const handleChangeSearchPattern = (event) => {

        let {name, type, value, checked} = event.target

        if (type == "checkbox") {
            value = checked ? 1 : 0
        } else {
            event.preventDefault();
        }

        setSearchPattern(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSearchFormSubmit = (event) => {
        event.preventDefault()

        fetchInvoices()

    }

    const getInvoiceIdFromEvent = (event) => {
        const closestTr = event.target.closest("tr")
        if (!closestTr) {
            alert("Heing ?")
            return
        }
        return closestTr.getAttribute("data-key")
    }

    const handleInvoiceClick = async (event, param) => {
        const id = getInvoiceIdFromEvent(event)
        if (!id) {
            return
        }

        const invoiceToUpdate = { [param]: 1 - invoices[id][param] }
        console.log(invoiceToUpdate)

        try {
            await axios().put(`/invoices/${invoices[id].id}`, invoiceToUpdate)
            const updatedInvoices = [...invoices]
            updatedInvoices[id][param] = 1 - invoices[id][param]
            setInvoices(updatedInvoices)
        } catch (error) {
            console.log(error.message);
        }
    }

    const handleGivenClick = async (event) => {
        handleInvoiceClick(event, "given")
    }

    const handlePaidClick = async (event) => {
        handleInvoiceClick(event, "paid")
    }

    const SearchPatternForm = () =>
        <div className="invoice-form">
            <form onSubmit={handleSearchFormSubmit} >
            <div className="form-row">
                <div className="form-cell label">No Facture</div>
                <div className="form-cell value"><input type="text" name="invoice_no" id="invoice_no" value={searchPattern.invoice_no} onChange={handleChangeSearchPattern} /></div>
            </div>
            <div className="form-row">
                <div className="form-cell label">Client</div>
                <div className="form-cell value"><input type="text" name="client" id="client" value={searchPattern.client} onChange={handleChangeSearchPattern} /></div>
            </div>
            <div className="form-row">
                <div className="form-cell label">Donné :</div>
                <div className="form-cell value"><input type="number" name="given" id="given"  value={searchPattern.given} onChange={handleChangeSearchPattern} /></div>
            </div>
            <div className="form-row">
                <div className="form-cell label">Payé :</div>
                <div className="form-cell value"><input type="number" name="paid" id="paid" value={searchPattern.paid} onChange={handleChangeSearchPattern} /></div>
            </div>
            <div className="form-row">
                <div className="form-cell label"> </div>
                <div className="form-cell value"><input type="submit" value="GO" /></div>
            </div>
            </form>
        </div>

    const InvoiceTable = () =>
        <table>
            <thead>
                <tr>
                    <th colSpan={2}> </th>
                    <th>No</th>
                    <th>Date Emission</th>
                    <th>Client</th>
                    <th>Montant</th>
                    <th>Notes</th>
                </tr>
            </thead>
            <tbody>
                {invoices.map((invoice, i) =>
                    <tr key={i} data-key={i}>
                        <td><FontAwesomeIcon icon={faHandshake} color={invoice.given ? "SeaGreen" : "Crimson"} onClick={handleGivenClick}/></td>
                        <td><FontAwesomeIcon icon={faEuroSign} color={invoice.paid ? "SeaGreen" : "Crimson"} onClick={handlePaidClick}/></td>
                        <td>{invoice.invoice_no}</td>
                        <td>{format(new Date(invoice.date_emission), 'dd/MM/yyyy')}</td>
                        <td>{invoice.client.name} {invoice.client.firstname}</td>
                        <td>{invoice.amount} €</td>
                        <td>{invoice.note}</td>
                    </tr>
                )}
            </tbody>
        </table>

    return (<InsideArticle status={status}>
        <article>
            <SearchPatternForm />
            {invoices?.length
                ? <InvoiceTable />
                : <div>Aucune facture</div>
            }
        </article>
    </InsideArticle>
        
    )
}