import {useEffect, useState, useRef} from "react"
// import useAuth from "../hooks/useAuth"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { authController } from "../controllers/authController"
import "./Login.sass"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons"


import axios from "../api/axios"

const LOGIN_URL = "/auth"

function Login() {

    // const {setAuth} = useAuth()

    const navigate = useNavigate()
    const location = useLocation()
    const from = location.state?.from?.pathname || '/'

    const userRef = useRef()
    const errRef = useRef()

    const [user, setUser] = useState('')
    const [pwd, setPwd] = useState('')
    const [errMsg, setErrMsg] = useState('')

    useEffect(() => {
        userRef.current.focus()
    }, [])

    useEffect(() => {
        setErrMsg('')
    }, [user, pwd])

    const handleSubmit = async (event) => {
        event.preventDefault()

        try {
            // console.log(user, pwd, {user, pwd})
            const response = await axios().post(
                LOGIN_URL, 
                {user, pwd} ,
                {
                    headers: {"Content-Type": 'application/json'},
                    // withCredentials: true

                } )
            // console.log(response?.data)
            // console.log(response?.data?.accessToken)
            // const accessToken = response?.data?.accessToken
            // const roles = response?.data?.roles
            authController.saveData(response?.data)
            // setAuth({ user, pwd, roles, accessToken})
            setUser('')
            setPwd('')
            navigate(from, {replace: true})
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No server response')
            } else if (err.response?.status == 400) {
                setErrMsg('Les 2 champs doivent remplis')
            } else if (err.response?.status == 401) {
                setErrMsg('Unauthorized')
            } else {
                setErrMsg('Login failed')
            }
            errRef.current.focus()
        }


    }


    return (
        <section>
            <div className="wrapper">
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <h1>Authentification</h1>
            <form onSubmit={handleSubmit}>
                <div className="input-box">                
                <input
                    type="text"
                    id="username"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUser(e.target.value)}
                    value={user}
                    placeholder="Utilisateur"
                    required />
                    <FontAwesomeIcon icon={faUser} />
                </div>
                <div className="input-box">                
                <input
                    type="password"
                    id="password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    placeholder="Mot de passe"
                    required />
                    <FontAwesomeIcon icon={faLock} />
                </div>
                

                <button>Entrer</button>
            </form>
            </div>
        </section>
    )
}

export default Login;
