import { useState, useEffect } from 'react';
import InsideArticle from "../article/InsideArticle";
import axios from '../../api/axios';
import Loading from '../loader/Loading';
import DateDisplay from '../display/DateDisplay';
import "./HKInvoiceArticle.sass";

const HKInvoiceArticle = ({ forHK = false }) => {

    const [status, setStatus] = useState({ ready: false, ok: false });
    const [housecleanList, setHousecleanList] = useState({ data: [], totalAmount: 0 });
    const [hkList, setHkList] = useState([]);
    const [searchPattern, setSearchPattern] = useState({});
    const [isFetching, setFetching] = useState(true);
    const [timerId, setTimerId] = useState(null);
    const [error, setError] = useState(null); // Ajout d'un état pour gérer les erreurs

    const fetchData = async () => {
        const todayDate = new Date();
        let initialPattern = { month: todayDate.getMonth() + 1, year: todayDate.getFullYear() };
        try {
            if (!forHK) {
                const response = await axios().get('/housekeepers');
                const receivedHKList = response.data
                    .filter((item) => item.active === 1)
                    .sort((a, b) => a.name.localeCompare(b.name));
                setHkList(receivedHKList);
                initialPattern.housekeeper = receivedHKList[0]?.id || null;
            }
            setSearchPattern(initialPattern);
            await fetchDataFromPattern(initialPattern);
        } catch (error) {
            setError('Failed to fetch housekeepers');
        }
    };

    const fetchDataFromPattern = async (pattern) => {
        setFetching(true);
        try {
            const url = forHK
                ? `/houseclean/month/${pattern.year}/${pattern.month}`
                : `/houseclean/month/${pattern.year}/${pattern.month}/${pattern.housekeeper}`;
            const response = await axios().get(url);
            const receivedHousecleanList = response.data;
            const totalAmount = receivedHousecleanList.reduce((sum, item) => sum + item.amount, 0);
            setHousecleanList({ data: receivedHousecleanList, totalAmount });
            setFetching(false);
            setStatus({ ready: true, ok: true });
        } catch (error) {
            setError('Failed to fetch houseclean data');
            setFetching(false);
            setStatus({ ready: true, ok: false });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleChangeSearchPattern = (event) => {
        event.preventDefault();
        clearTimeout(timerId);

        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;

        const newSearchPattern = { ...searchPattern };
        newSearchPattern[fieldName] = fieldValue;
        setSearchPattern(newSearchPattern);

        const id = setTimeout(() => fetchDataFromPattern(newSearchPattern), 800);
        setTimerId(id);
    };

    const SearchPatternForm = () => (
        <div className="invoice-form">
            <div className="form-row">
                <div className="form-cell label">Mois :</div>
                <div className="form-cell value">
                    <input
                        type="number"
                        name="month"
                        id="month"
                        value={searchPattern.month}
                        onChange={handleChangeSearchPattern}
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-cell label">Année :</div>
                <div className="form-cell value">
                    <input
                        type="number"
                        name="year"
                        id="year"
                        value={searchPattern.year}
                        onChange={handleChangeSearchPattern}
                    />
                </div>
            </div>
            {!forHK && (
                <div className="form-row">
                    <div className="form-cell label">Aide ménagère :</div>
                    <div className="form-cell value">
                        <select name="housekeeper" value={searchPattern.housekeeper} onChange={handleChangeSearchPattern}>
                            {hkList.map((item, idx) => (
                                <option key={`hk${idx}`} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            )}
        </div>
    );

    const HouseCleanTable = () => (
        <table>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Propriété</th>
                    <th>Tarif</th>
                    {!forHK && <th>Voyageur</th>}
                </tr>
            </thead>
            <tbody>
                {housecleanList.data.map((item, i) => (
                    <tr key={i}>
                        <td><DateDisplay value={item.dt_to} /></td>
                        <td>{item.name}</td>
                        <td>{item.amount} €</td>
                        {!forHK && <td>{item.traveler}</td>}
                    </tr>
                ))}
            </tbody>
        </table>
    );

    const HousecleanTotalDiv = () => (
        <div className="total-amount">Total : {housecleanList.totalAmount}&nbsp;€ </div>
    );

    return (
        <InsideArticle status={status}>
            <article>
                <SearchPatternForm />
                {error && <div className="error">{error}</div>}
                {isFetching
                    ? <Loading />
                    : housecleanList.data.length
                        ? <>
                            <HouseCleanTable />
                            <HousecleanTotalDiv />
                        </>
                        : <div>Aucune prestation</div>
                }
            </article>
        </InsideArticle>
    );
};

export default HKInvoiceArticle;
