import { useParams, useNavigate } from "react-router-dom";
import { updateProperty } from "../utils/RestLocem";
import { useState, useEffect } from "react";
import { format } from "date-fns";
import axios from "../api/axios"
import { Navigate } from 'react-router-dom';
import Loading from "../components/loader/Loading";
import EditPanel from "../components/panel/EditPanel";

function PropertyEditor() {

    const [property, setProperty] = useState({ok:false, loading:true})
    const params = useParams() || {} ;
    const id = params.id || undefined;
    const navigate = useNavigate()

    const fieldsMap = [
        {field:"name", label:"Nom interne"},
        {field:"address", label:"Adresse"},
        {field:"add_comp", label:"Complément"},
        {field:"cp", label:"Code Postal"},
        {field:"city", label:"Ville"},
        {field:"commission", label:"Commission", type:"number", suffix:"%", placeholder:'20' },
        {field:"houseclean", label:"Frais de ménage", type:"number", suffix:"€", placeholder:'50' },
        {field:"surface", label:"Surface", type:"number", suffix:"m²", placeholder:'20' },
        {field:"max_travelers", label:"Nombre de voyageurs", type:"number", placeholder:'2' },
        {field:"wifi_ssid", label:"Wifi SSID" },
        {field:"wifi_key", label:"Wifi Clé" },
        {field:"description", label:"Description"},
        {field:"dishwasher", label:"Lave-vaisselle", type:"bool"},
        {field:"washing_machine", label:"Lave-linge", type:"bool"},
        {field:"clim", label:"Climatisation", type:"bool"},
        {field:"ventilateur", label:"Ventilateur", type:"bool"},
        {field:"parking", label:"Parking", type:"bool"},
        {field:"animals_allowed", label:"Animaux autorisés", type:"bool"},
        {field:"lit_parapluie", label:"Lit Parapluie", type:"bool"},
        {field:"towels", label:"Serviettes", type:"number"},
        {field:"beds", label:"Lit", placeholder:"140,160,..."},
        {field:"comforter", label:"Housses de couettes", type:"number"},
        {field:"pillowcases", label:"Taies", type:"number"},
        {field:"contract_id", label:"Numéro de contrat"},
        {field:"airbnb_id", label:"Airbnb ID"},
        {field:"booking_id", label:"Booking ID"},
        {field:"clim_last_checkup", label:"Checkup Clim", type:"date"},
    ]

    useEffect(() => {
        async function fetchProperty() {
            try {
                const response = await axios().get(`/properties/${id}`)
                const p = {  ...response.data, ok:true, loading:false }
                setProperty(p)
            } catch (error) {
                if (error.response) {
                    let { status, statusText } = error.response;
                    if (status == 404) {
                        setProperty({ok:false, loading:false})
                    } else {
                        setProperty({ok:false, loading:true})
                        console.log(status, statusText);
                    }
                } else {
                    setProperty({ok:false, loading:true})
                    // console.log(error);
                }
            }
        }
        fetchProperty()

        
    }, []);


    const handleSave = async (data) => {
        console.log(data)
        try {
            await updateProperty({ ...data, id:property.id})
            navigate("..", { replace: true })
        } catch (e) {
            alert("Problème dans l'envoi")
        }
    }

    return property.loading
        ? <Loading />
        : !property.ok
            ? <Navigate to="/unauthorized" replace={true}/>
            : <>
                <h2>Edition Logement</h2>
                <EditPanel fields={fieldsMap} data={property} onSave={handleSave}/>
            </>


}

export default PropertyEditor;
