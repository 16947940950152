import { getNextCheckout } from "../utils/RestLocem"

import ChekinTable from "../components/checkin/ChekinTable";

function CheckinToday() {

    const loadNextCheckout = async (nbdays) => {
        return await getNextCheckout(nbdays)
    }

    return (
        <ChekinTable loadReservations={() => { return loadNextCheckout(0) }} />
    );
}

export default CheckinToday;