import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from "../api/axios";
import './PrivateCsvDeposit.css'; // Importer un fichier CSS pour le style

function PrivateCsvDeposit() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null); // État pour les messages de retour

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]); // On prend seulement le premier fichier
    setUploadStatus(null); // Réinitialiser le message de statut lors de la sélection d'un nouveau fichier
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.csv', // On limite les fichiers acceptés aux fichiers CSV
    multiple: false // Permettre l'upload d'un seul fichier
  });

  const uploadFile = async () => {
    if (!selectedFile) {
      setUploadStatus({ type: 'error', message: 'Aucun fichier sélectionné' });
      return;
    }

    try {
      const formData = new FormData();  // Créer un objet FormData
      formData.append('file', selectedFile);  // Ajouter le fichier dans le formData sous la clé 'file'

      // Envoyer la requête PUT avec axios
      const response = await axios().put('/transactions/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',  // Assurer que le type de contenu est multipart/form-data
        },
      });

      setUploadStatus({ type: 'success', message: response.data.message }); // Enregistrer le message de succès
    } catch (error) {
      if (error.response) {
        // Si le serveur a renvoyé une réponse avec un code d'erreur
        setUploadStatus({ type: 'error', message: error.response.data.message || 'Erreur lors de l\'upload' });
      } else {
        // Si l'erreur est due à autre chose (réseau, etc.)
        setUploadStatus({ type: 'error', message: 'Erreur réseau ou serveur non joignable' });
      }
    }
  };

  return (
    <div className="upload-container">
      <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Déposez le fichier ici...</p>
        ) : selectedFile ? (
          <p>{selectedFile.name}</p>
        ) : (
          <p>Glissez et déposez un fichier CSV ici, ou cliquez pour sélectionner un fichier</p>
        )}
      </div>
      <button onClick={uploadFile} disabled={!selectedFile} className="upload-button">
        Upload File
      </button>

      {/* Afficher le message de retour de l'API */}
      {uploadStatus && (
        <div className={`upload-status ${uploadStatus.type}`}>
          {uploadStatus.message}
        </div>
      )}
    </div>
  );
}

export default PrivateCsvDeposit;
