import logo from "./LocEM_web.png"
import "./Navbar.sass"
import { Link } from "react-router-dom";
import { useState, useEffect } from "react"
import { faBroom, faLock, faSignOutAlt, faUsers, faBuilding, faTasks, faFileInvoiceDollar , faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { authController } from "../../controllers/authController";

function Navbar() {

  const [showLinks, setShowLinks] = useState(false)
  const [tabs, setTabs] = useState([])

  useEffect (() => {
    const tab = [
      { path: '/checkin', label: 'Réservations', icon: faCalendar, allowedRoles:[1,2] },
      { path: '/houseclean', label: 'Ménage', icon: faTasks, allowedRoles:[2] },
      { path: '/properties', label: 'Logements', icon: faBuilding, allowedRoles:[1,2] },
      { path: '/clients', label: 'Clients', icon: faUsers, allowedRoles:[2] },
      { path: '/invoices', label: 'Facturation', icon: faFileInvoiceDollar, allowedRoles:[2] },
      { path: '/hk', label: 'Mes ménages', icon: faBroom, allowedRoles:[3,2] },
      { path: '/private', label: 'Privé', icon: faLock, allowedRoles:[2] },
    ]
  
    if (authController.isLogged()) {
      tab.push({ path: '/logout', label: 'Se déconnecter', icon: faSignOutAlt })
    } else {
      tab.push({ path: '/login', label: 'Se connecter', icon: faSignInAlt })
    }
    setTabs(tab)

  }, [authController.isLogged()])

  
  const isAllowed = (allowedRoles) => {
    return (
      allowedRoles 
      ? authController.getRoles()?.find(role => allowedRoles.includes(role))
      : true
    )
  }

  const toggleShowLinks = () => {
    setShowLinks(!showLinks)
  }

  const handleHideLinks = (event) => {
    // event.preventDeafult()
    setShowLinks(false)
  }

  return (<nav className={`navbar ${showLinks ? "show-nav" : "hide-nav"}`}>
    <div className="nav-logo"><img src={logo} style={{ verticalAlign: 'text-top' }} /></div>
    <ul className="nav-links">
      {tabs.filter(tab => isAllowed(tab.allowedRoles)).map((tab) => (
        <li key={tab.path}>
          <Link
            to={tab.path}
            onClick={handleHideLinks}
            className={tab.path === location.pathname ? "active" : ""}><FontAwesomeIcon icon={tab.icon} color="SeaGreen" /><span className="nav-label">{tab.label}</span></Link>
        </li>
      ))}
    </ul>
    <button className="nav-burger" onClick={toggleShowLinks}><span className="burger-line"></span></button>
  </nav>
  );
}

export default Navbar;