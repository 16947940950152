import { useState, useEffect } from 'react'
import axios from "../api/axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from 'date-fns'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

import Loading from '../components/loader/Loading'

const PrivateTodo = () => {

    const [todo, setTodo] = useState({ok:false, loading:true})


    useEffect(() => {


        const fetchData = async () => {
            try {
                const response = await axios().get('/sms')
                const p = { data:[...response.data], ok:true, loading:false }
                console.log(p)
                setTodo(p)
            } catch (error) {
                if (error.response) {
                    let { status, statusText } = error.response;
                    if (status == 404) {
                        setTodo({ok:false, loading:false})
                    } else {
                        setTodo({ok:false, loading:true})
                        console.log(status, statusText);
                    }
                } else {
                    setTodo({ok:false, loading:true})
                    // console.log(error);
                }
            }
        }

        fetchData()

    },[])

    return todo.loading
        ? <Loading />
        : !todo.ok
            ? <Navigate to="/unauthorized" replace={true} />
            : <article>
                {todo.data.length
                    ? (
                        <table>
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Date</th>
                                    <th>Numéro</th>
                                    <th>Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                {todo.data.map((item, i) =>
                                    <tr key={i}>
                                        <td><FontAwesomeIcon icon={faCircle} color={item.status === 200 ? 'SeaGreen' : 'Crimson'} title={"" + item.status} /></td>
                                        <td>{format(new Date(item.sendDate), 'dd/MM HH:mm')}</td>
                                        <td>{item.num}</td>
                                        <td><pre>{item.message}</pre></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )
                    : (
                        <p>Rien...</p>
                    )}
            </article>

}

export default PrivateTodo