import { useLocation, Outlet, Link } from "react-router-dom"
import './SubNavbarLayout.sass'


function SubNavbarLayout(props) {

    const location = useLocation();

    const tabs = props.tabs

    return (
        <>
            { props?.title ? <h2>{ props.title }</h2> : ""}
            <nav id="subnavbar">
                <ul>
                    {tabs.map((tab, i) => (
                        <li key={`_subnav_${i}`}>
                            <Link
                                to={tab.path}
                                className={tab.path === location.pathname ? "active" : ""}>{tab.label}</Link>
                        </li>
                    ))}
                </ul>
            </nav>
            <article>
                <Outlet />
            </article>
        </>
    );
}

export default SubNavbarLayout;