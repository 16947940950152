import { useState, useEffect } from 'react'
import axios from "../api/axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons"
// import { format } from 'date-fns'
import buildDisplayDate from '../utils/Utils'
import {updateReservation} from "../utils/RestLocem"
import "./PrivateDevices.sass"
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons'

const HousecleanBags = () => {

    const [planning, setPlanning] = useState()


    useEffect(() => {


        const getPlanning = async () => {
            try {
                const response = await axios().get('/reservations/hc-planning')
                // console.log(response.data)
                setPlanning(response.data)
            } catch (err) {
                console.log(err)
            }
        }

        getPlanning()

    },[])

    const handleSacSelect = async (event) => {
        _handleSacClick(event, "hc_sac_ok", 1)
    }

    const handleSacUnselect = async (event) => {
        _handleSacClick(event, "hc_sac_ok", 0)
    }

    const handleLivreSelect = async (event) => {
        _handleSacClick(event, "hc_sac_livre", 1)
    }

    const handleLivreUnselct = async (event) => {
        _handleSacClick(event, "hc_sac_livre", 0)
    }

    const _handleSacClick = async (event, key, value) => {
        const closestTr = event.target.closest("tr")
        if (!closestTr)
            return
        const id = closestTr.getAttribute("data-key")
        
        const line = planning[id]
        await updateReservation({id:line.id, [key]: value})
        line[key] = value
        const newPlanning = [ ...planning ]
        newPlanning[id] = line
        setPlanning(newPlanning)
    }



    return (
        <article>
            {planning?.length
                ? (
                    <table id="hc-planning-table">
                        <thead>
                            <tr>
                                <th>-</th>
                                <th>Fait</th>
                                <th>Date</th>
                                <th>Propriété</th>
                                <th>Serv. de bain</th>
                                <th>Drap de bain</th>
                                <th>Desc. de bain</th>
                                <th>Gant</th>
                                <th>Torchon</th>
                                <th>Lit 1</th>
                                <th>Lit 2</th>
                                <th>Lit 3</th>
                                <th>Housse</th>
                                <th>Taies</th>
                                <th>Livré</th>
                            </tr>
                        </thead>
                        <tbody>
                            {planning.map((item, i) =>
                                <tr key={i} data-key={i}>
                                    <td>{item?.housekeeper == 21 ? <FontAwesomeIcon icon={faShoppingBag} title='OK' color='SeaGreen' size="lg" /> : <FontAwesomeIcon icon={faShoppingBag} title='A faire' color='LightGray' size="lg" />}</td>
                                    <td>{item?.hc_sac_ok
                                        ? <FontAwesomeIcon icon={faCheckSquare} title='OK' color='SeaGreen' size="lg" onClick={handleSacUnselect}/>
                                        : <FontAwesomeIcon icon={faSquare} title='A faire' color='Gray' size="lg" onClick={handleSacSelect}/>}</td>
                                    <td>{buildDisplayDate(item?.dt_to)}</td>
                                    <td>{item?.property.name}</td>
                                    <td className="not-media" style={{textAlign: "center"}}>{item?.property.towels}</td>
                                    <td className="not-media" style={{textAlign: "center"}}>{item?.property.towels}</td>
                                    <td className="not-media" style={{textAlign: "center"}}>1</td>
                                    <td className="not-media" style={{textAlign: "center"}}>1</td>
                                    <td className="not-media" style={{textAlign: "center"}}>1</td>
                                    {(item?.property.beds + ",-,-").split(',').slice(0, 3).map((bed) => <td className="not-media" style={{textAlign: "center"}}>{bed}</td> )}
                                    <td className="not-media" style={{textAlign: "center"}}>{item?.property.comforter}</td>
                                    <td className="not-media" style={{textAlign: "center"}}>{item?.property.pillowcases}</td>
                                    <td>{item?.hc_sac_livre
                                        ? <FontAwesomeIcon icon={faCheckSquare} title='OK' color='SeaGreen' size="lg"  onClick={handleLivreUnselct}/>
                                        : <FontAwesomeIcon icon={faSquare} title='A déposer' color='Gray' size="lg"  onClick={handleLivreSelect}/>}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )
                : (
                    <p>Rien...</p>
                )}
        </article>
    )
}

export default HousecleanBags