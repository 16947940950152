import { getNextCheckin } from "../utils/RestLocem"

import ChekinTable from "../components/checkin/ChekinTable";

function CheckinNext() {

    const loadNextCheckin = async (nbdays) => {
        return await getNextCheckin(nbdays)
    }

    return (
        <ChekinTable loadReservations={() => { return loadNextCheckin(8) }} />
    );
}

export default CheckinNext;