import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "../api/axios"
import { Navigate } from 'react-router-dom';
import Loading from "../components/loader/Loading";
import buildDisplayDate from "../utils/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceFrown, faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import { faAirbnb } from "@fortawesome/free-brands-svg-icons";
import { differenceInDays } from "date-fns";
import { faCircle, faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import BrokerDisplay from "../components/display/BrokerDisplay";

function PropertyPlanning() {

    const [property, setProperty] = useState({ok:false, loading:true})
    const [reservations, setReservations] = useState()
    const params = useParams() || {} ;
    const id = params.id || undefined;

    useEffect(() => {
        async function fetchProperty() {
            try {
                const r = await axios().get(`/reservations/property/${id}?withHousekeeper=1`)
                let editedReservations = r.data
                // editedReservations = editedReservations.filter(x => new Date(x.dt_to) >= new Date())
                editedReservations.sort((a, b) => new Date(a.dt_to) - new Date(b.dt_to))
                setReservations(editedReservations)
                const response = await axios().get(`/properties/${id}`)
                const p = {  ...response.data, ok:true, loading:false }
                setProperty(p)
            } catch (error) {
                if (error.response) {
                    let { status, statusText } = error.response;
                    if (status == 404) {
                        setProperty({ok:false, loading:false})
                    } else {
                        setProperty({ok:false, loading:true})
                        console.log(status, statusText);
                    }
                } else {
                    setProperty({ok:false, loading:true})
                    // console.log(error);
                }
            }
        }
        fetchProperty()
    }, []);

    return property.loading
        ? <Loading />
        : !property.ok
            ? <Navigate to="/unauthorized" replace={true}/>
            :
            <>
                <h1>Logement {property.name}</h1>
                { reservations.length > 0 
                ?
                <table>
                    <tbody>
                    {reservations.map((item, i) => {
                        const nuit = differenceInDays(new Date(item.dt_to), new Date(item.dt_from))
                        return <tr key={i}>
                            <td>
                                {item.tbcancelled
                                    ? <FontAwesomeIcon icon={faCircleMinus} color="gray" title="Annulé" />
                                    : ""}
                                {item.tbchanged
                                    ? <FontAwesomeIcon icon={faCircle} color="gray" title="Modifié" />
                                    : ""}
                                {item.tbcreated
                                    ? <FontAwesomeIcon icon={faCirclePlus} color="gray" title="Nouveau" />
                                    : " "}
                            </td>
                            <td><BrokerDisplay reservation={item} property={property}></BrokerDisplay></td>
                            <td><Link to={`/r/${item.id}`}>{buildDisplayDate(item.dt_from)} - {buildDisplayDate(item.dt_to)}</Link></td>
                            <td>{nuit + " nuit" + (nuit>1 ? "s" : "")}</td>
                            <td>{item.traveler} { item.is_airbnb && item.message_id ? <a href={`https://www.airbnb.fr/hosting/messages/${item.message_id}`} target={`_inbox_${item.message_id}`}><FontAwesomeIcon icon={faCommentAlt} color="#FF5A5F" /></a> : "" }</td>
                            <td>{item.housekeeper?.name}</td>
                            </tr>
                    })}
                
                    </tbody>
                </table>
                : <>Rien... <FontAwesomeIcon icon={faFaceFrown}/></>
                }
            </>



}

export default PropertyPlanning;
