import { useEffect, useState } from "react";
import { getHouseKeepers, getNextCheckout, sendSms, updateReservation } from "../../utils/RestLocem";
import { format } from "date-fns";
import buildDisplayDate from "../../utils/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCommentAlt, faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { faBan, faCheck, faFilePen, faSyncAlt, faUserPlus, faUserSlash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import DateDisplay from "../display/DateDisplay";
import BrokerDisplay from "../display/BrokerDisplay";
import "./HouseClean.sass"
import SMSPopup from "./SmsPopup";


function HousecleanTable() {

    const pageTitle = "Ménage"
    const [hcList, setHcList] = useState([])
    const [editHousecleanId, setEditHousecleanId] = useState(null)
    const [selectedHousekeeperId, setSelectedHousekeeperId] = useState(0)
    const [housekeepers, setHousekeepers] = useState([])

    const [smsPopup, setSmsPopup] = useState(false)
    const [smsFormData, setSmsFormData] = useState({})

    const [smsDataList, setSmsDataList] = useState([])
    const [ smsDataListIndex, setSmsDataListIndex ] = useState(0)

    const loadHousekeepers = async () => {
        const receivedHousekepers = await getHouseKeepers();
        const housekeepersToStore = receivedHousekepers.filter(x => x.active == 1)
        setHousekeepers(housekeepersToStore);
    }

    const loadNextCheckout = async () => {
        const resas = await getNextCheckout(10);
        setHcList(resas)
    }

    const handleSmsFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;

        const newFormData = { ...smsFormData };
        newFormData[fieldName] = fieldValue;
        setSmsFormData(newFormData);
    }

    const displaySmsPopup = (event) => {
        event.preventDefault()

        const todayDate = new Date()
        const nowDate = new Date()
        todayDate.setHours(0,0,0,0)
        const tomorrowDate = new Date(nowDate)
        tomorrowDate.setDate(tomorrowDate.getDate() + 1)
        const d2Date = new Date(nowDate)
        d2Date.setDate(d2Date.getDate() + 2)
        const listToSend = hcList.filter(x => new Date(x.dt_to) <= d2Date && x.do_houseclean) 
        // console.log(listToSend)
        const tmpSmsDataList = []
        setSmsDataList([])
        setSmsDataListIndex(0)
        for (let hk of housekeepers) {
            const smsData = {hk:hk, resas:listToSend.filter(x => x.housekeeper?.id == hk.id)}
            if (smsData.resas.length != 0)
            tmpSmsDataList.push(smsData)

        }
        const smsFormDataList = []
        if (tmpSmsDataList.length != 0) {
            for (const smsData of tmpSmsDataList) {
                // console.log(smsData)
                const phone = smsData.hk.phone
                let message = `Bonjour ${smsData.hk.name}\n**Planning à venir**\n`
                const todayResaList = smsData.resas.filter(x => new Date(x.dt_to) <= nowDate)
                // console.log(todayResaList)
                if (todayResaList.length > 0) {
                    message += `Aujourd'hui :\n`
                    for (const hc of todayResaList) {
                        let win = `${hc.wished_checkout} - 17:00`
                        let reloue = ""
                        if (hc.next.id != undefined) {
                            const nextToDate = new Date(hc.next.dt_from)
                            nextToDate.setHours(0,0,0,0)
                            if (todayDate.toDateString() == nextToDate.toDateString()) {
                               win = `${hc.wished_checkout} - ${hc.next.wished_checkin}`
                               reloue = " - reloué"
                            }
                        } 
                        message += `${win} : ${hc.property.name}${reloue}\n`
                        // console.log(hc)
                    }
                }
                const tomorrowResaList = smsData.resas.filter(x => new Date(x.dt_to) > nowDate && new Date(x.dt_to) <= tomorrowDate)
                if (tomorrowResaList.length > 0) {
                    message += `Demain : ${tomorrowResaList.map(x => x.property.name).join(", ")}\n`
                }
                const otherResaList = smsData.resas.filter(x => new Date(x.dt_to) >= tomorrowDate)
                if (otherResaList.length > 0) {
                    message += `${buildDisplayDate(otherResaList[0].dt_to)} : ${otherResaList.length} ménage${otherResaList.length > 1 ? "s" : ""}\n`
                }                
                smsFormDataList.push({phone:phone, text:message.trim()})
            }
            //console.log(smsFormDataList)
            setSmsDataList(smsFormDataList)
            setSmsFormData(smsFormDataList[smsDataListIndex])
            setSmsPopup(true)
        }
    }

    const handleSmsSendSubmit = (event) => {
        event.preventDefault();
        sendSms(smsFormData.phone, smsFormData.text)
        nextSms()
    }

    const handleSmsSendNext = (event) => {
        event.preventDefault();
        nextSms()
    }

    const nextSms = () => {

        const newIdx = smsDataListIndex + 1
        setSmsPopup(false)
        if (newIdx < smsDataList.length) {
            setSmsDataListIndex(newIdx)
            const formData = smsDataList[newIdx]
            setSmsFormData(formData)
            setSmsPopup(true)
        } else {
            setSmsDataListIndex(0)
            setSmsFormData({})
        }

    }

    const handleAddClick = async (event) => {
        const [idx, reservation] = getLine(event)
        setSelectedHousekeeperId(0)
        setEditHousecleanId(idx)
    }

    const handleFormCancelClick = async (event) => {
        setEditHousecleanId(null)
    }

    const handleFormValidClick = async (event) => {
        const selectedHousekeeper = housekeepers.find(hk => hk.id == selectedHousekeeperId);
        const [idx, reservation] = getLine(event)
        if (selectedHousekeeper) {
            updateReservation({
                id: reservation.id,
                housekeeper: selectedHousekeeperId
            })

            const toDate = new Date(reservation.dt_to)
            toDate.setHours(0,0,0,0)
            let dt_to = format(toDate, 'EEE. dd/MM')
            dt_to = dt_to.replace("Mon","Lun").replace("Tue","Mar").replace("Wed","Mer").replace("Thu","Jeu").replace("Fri","Ven").replace("Sat","Sam").replace("Sun","Dim")
            const text = `Bonjour ${selectedHousekeeper.name}\nNouvelle prestation de ménage:\n${dt_to} - ${reservation.property.name}`
            sendSms(selectedHousekeeper.phone, text)

            const newHcList = [ ...hcList ]
            newHcList[idx].housekeeper = selectedHousekeeper
            setHcList(newHcList)
        }
        setEditHousecleanId(null)
    }

    const handleHcDoneClick = (event) => {
        handleToggleState(event, "hc_done")
    }

    const handleSupClick = (event) => {

        const [index, resa]= getLine(event)

        updateReservation({ 
            id:resa.id,
            housekeeper:null
        })

        const hkName = resa.housekeeper.name
        const hkPhone = resa.housekeeper.phone
 
        const newResas = [...hcList];

        /** CODE EN DOUBLE ENTRE REACT ET EXPRESS */
        const toDate = new Date(resa.dt_to)
        toDate.setHours(0,0,0,0)
        let dt_to = format(toDate, 'EEE. dd/MM')
        dt_to = dt_to.replace("Mon","Lun").replace("Tue","Mar").replace("Wed","Mer").replace("Thu","Jeu").replace("Fri","Ven").replace("Sat","Sam").replace("Sun","Dim")
        const text = `Bonjour ${hkName}\nPrestation de ménage annulée:\n${dt_to} - ${resa.property.name}`
        sendSms(hkPhone, text)

        const newResa = { ...hcList[index], housekeeper:null }

        newResas[index] = newResa;

        setHcList(newResas);
    }

    useEffect(() => {
        loadNextCheckout()
        loadHousekeepers()
    }, []);
    
    const isToday = (date) => {
        const dateDate = new Date(date)
        dateDate.setHours(0,0,0,0)
        const todayDate = new Date()
        todayDate.setHours(0,0,0,0)
        return dateDate.toDateString() == todayDate.toDateString()
    }

    const handleDoHousecleanClick = async (event) => {
        handleToggleState(event, "do_houseclean")
    }

    const getLine = (event) => {
        const closestTr = event.target.closest("div.hc-row")
        if (!closestTr)
            return
        const id = closestTr.getAttribute("data-key")
        const line = hcList[id]
        return [id, line]
    }

    const handleToggleState = async (event, key) => {

        event.preventDefault()

        const [id, line] = getLine(event)
        if (!line){
            return
        }

        const newValue = line[key] ? 1 - line[key] : 1

        await updateReservation({id:line.id, [key]: newValue})
        line[key] = newValue
        const newHcList = [ ...hcList ]
        newHcList[id] = line
        setHcList(newHcList)
    }

    const ReadOnlyHousekeeperTd = ({reservation}) => {
        return <td data-label="Aide Ménagère">
        {reservation.housekeeper
            ? <>{reservation.housekeeper.name} <FontAwesomeIcon icon={faUserSlash} title='Supprimer' color='Crimson' size="sm" onClick={handleSupClick} /></>
            : <FontAwesomeIcon icon={faUserPlus} title='Affecter' color='SeaGreen' size="sm" onClick={handleAddClick} />
        }
    </td>
    }

    
    const ReadOnlyHousekeeperDiv = ({reservation}) => {
        return <div className="hc-cell" data-label="Aide Ménagère">
        {reservation.housekeeper
            ? <>{reservation.housekeeper.name} <FontAwesomeIcon icon={faUserSlash} title='Supprimer' color='Crimson' size="sm" onClick={handleSupClick} /></>
            : <FontAwesomeIcon icon={faUserPlus} title='Affecter' color='SeaGreen' size="sm" onClick={handleAddClick} />
        }
    </div>
    }

    const handleHousekeeperSelect = (event) => {
        event.preventDefault()
        const {value} = event.target
        setSelectedHousekeeperId(value)
    }

    const EditableHousekeeperTd = ({ reservation }) => {
        // console.log(housekeepers)
        return <td data-label="Aide Ménagère">
            <select name="selectHK" value={selectedHousekeeperId} onChange={handleHousekeeperSelect}>
                <option value="0">--</option>
                {housekeepers.map((items, idx) => {
                    return <option key={`hk${idx}`} value={items.id}>
                        {items.name}
                    </option>
                })}
            </select>
            &nbsp;
            <FontAwesomeIcon icon={faCheckCircle} color="SeaGreen" size="sm" title="Valider" onClick={handleFormValidClick} />
            &nbsp;
            <FontAwesomeIcon icon={faTimesCircle} color="Crimson" size="sm" title="Annuler" onClick={handleFormCancelClick}/>
        </td>
    }

    const EditableHousekeeperDiv = ({ reservation }) => {
        // console.log(housekeepers)
        return <div className="hc-cell" data-label="Aide Ménagère">
            <select name="selectHK" value={selectedHousekeeperId} onChange={handleHousekeeperSelect}>
                <option value="0">--</option>
                {housekeepers.map((items, idx) => {
                    return <option key={`hk${idx}`} value={items.id}>
                        {items.name}
                    </option>
                })}
            </select>
            &nbsp;
            <FontAwesomeIcon icon={faCheckCircle} color="SeaGreen" size="sm" title="Valider" onClick={handleFormValidClick} />
            &nbsp;
            <FontAwesomeIcon icon={faTimesCircle} color="Crimson" size="sm" title="Annuler" onClick={handleFormCancelClick}/>
        </div>
    }
    
    const ReservationTr = ({ reservation, 'data-key': dataKey }) => {
        const today =isToday(reservation.dt_to)
        let oneDay = false
        let win = `${reservation.wished_checkout} - 17:00`
        const toDate = new Date(reservation.dt_to)
        toDate.setHours(0,0,0,0)

        if (reservation.next != undefined) {
            const nextToDate = new Date(reservation.next.dt_from)
            nextToDate.setHours(0,0,0,0)
            oneDay = toDate.toDateString() == nextToDate.toDateString()
            if (oneDay) {
                win = `${reservation.wished_checkout} - ${reservation.next.wished_checkin}`
             }
        } 
        return (
            <tr className={today ? "today" : ""} data-key={dataKey}>
                <td data-label="Date">
                    <DateDisplay value={reservation.dt_to} />
                    &nbsp;
                    {reservation.is_airbnb && reservation.message_id
                        ? <a href={`https://www.airbnb.fr/hosting/messages/${reservation.message_id}`} target={`_inbox_${reservation.message_id}`}><FontAwesomeIcon icon={faCommentAlt} color="#FF5A5F" /></a>
                        : ""
                    }
                </td>
                <td className="c" data-label="Reloué">
                    {oneDay
                        ?
                        <>
                            <FontAwesomeIcon icon={faSyncAlt} title='Reloué' color='SeaGreen' size="lg" />
                            &nbsp;
                            {reservation.next.is_airbnb && reservation.next.message_id
                                ? <a href={`https://www.airbnb.fr/hosting/messages/${reservation.next.message_id}`} target={`_inbox_${reservation.next.message_id}`}><FontAwesomeIcon icon={faCommentAlt} color="#FF5A5F" /></a>
                                : ""}
                        </>
                        : "\u00A0"
                    }
                </td>
                <td data-label="Logement" style={{display: "flex", alignItems: "center"}}>
                    <BrokerDisplay reservation={reservation} />
                    <Link to={`/p/${reservation.property.id}`} >{reservation.property.name}</Link>
                </td>
                <td data-label="Plage">{win}</td>
                <td data-label=" ">
                    <FontAwesomeIcon icon={faBan} title='Pas de ménage' color={reservation.do_houseclean ? "LightGrey" : "Crimson"} onClick={handleDoHousecleanClick} />
                </td>
                {reservation.do_houseclean
                    ? <>
                        {editHousecleanId == dataKey
                            ? <EditableHousekeeperTd />
                            : <ReadOnlyHousekeeperTd reservation={reservation} />
                        }
                        <td className="c" data-label="Fait">{today ? (reservation.hc_done ? (<FontAwesomeIcon icon={faCheck} title='OK' color='SeaGreen' size="lg" />) : <button onClick={handleHcDoneClick}>Fait</button>) : "\u00A0"}</td>
                        <td data-label="Rapport">{today && reservation.hc_done ? "\u000A" : <a href={`https://forms.fillout.com/t/3THorEGZbius?resa=${reservation.id}&pname=${reservation.property.name}`}><FontAwesomeIcon icon={faFilePen} title='Rapport' color='Grey' /></a>}</td>
                    </>
                    : <><td></td><td></td><td></td></> }
            </tr>
        )
    }

    const ReservationDiv = ({ reservation, 'data-key': dataKey }) => {
        const today =isToday(reservation.dt_to)
        let oneDay = false
        let win = `${reservation.wished_checkout} - 17:00`
        const toDate = new Date(reservation.dt_to)
        toDate.setHours(0,0,0,0)

        if (reservation.next != undefined) {
            const nextToDate = new Date(reservation.next.dt_from)
            nextToDate.setHours(0,0,0,0)
            oneDay = toDate.toDateString() == nextToDate.toDateString()
            if (oneDay) {
                win = `${reservation.wished_checkout} - ${reservation.next.wished_checkin}`
             }
        } 
        return (
            <div className={"hc-row" + (today ? " today" : "")} data-key={dataKey}>
                <div className="hc-cell date" data-label="Date">
                    <DateDisplay value={reservation.dt_to} />
                    &nbsp;
                    {reservation.is_airbnb && reservation.message_id
                        ? <a href={`https://www.airbnb.fr/hosting/messages/${reservation.message_id}`} target={`_inbox_${reservation.message_id}`}><FontAwesomeIcon icon={faCommentAlt} color="#FF5A5F" /></a>
                        : ""
                    }
                </div>
                <div className="hc-cell reloue" data-label="Reloué">
                    {oneDay
                        ?
                        <>
                            <FontAwesomeIcon icon={faSyncAlt} title='Reloué' color='SeaGreen' size="lg" />
                            &nbsp;
                            {reservation.next.is_airbnb && reservation.next.message_id
                                ? <a href={`https://www.airbnb.fr/hosting/messages/${reservation.next.message_id}`} target={`_inbox_${reservation.next.message_id}`}><FontAwesomeIcon icon={faCommentAlt} color="#FF5A5F" /></a>
                                : ""}
                        </>
                        : "\u00A0"
                    }
                </div>
                <div className="hc-cell"  data-label="Plage">{win}</div>
                <div className="hc-cell" data-label="Logement" style={{display: "flex", alignItems: "center"}}>
                    <BrokerDisplay reservation={reservation} />
                    <Link to={`/p/${reservation.property.id}`} >{reservation.property.name}</Link>
                </div>

                <div className="hc-cell no-hc"  data-label=" ">
                    <FontAwesomeIcon icon={faBan} title='Pas de ménage' color={reservation.do_houseclean ? "LightGrey" : "Crimson"} onClick={handleDoHousecleanClick} />
                </div>
                {reservation.do_houseclean
                    ? <>
                        {editHousecleanId == dataKey
                            ? <EditableHousekeeperDiv />
                            : <ReadOnlyHousekeeperDiv reservation={reservation} />
                        }
                        <div className="hc-cell done" data-label="Fait">{today ? (reservation.hc_done ? (<FontAwesomeIcon icon={faCheck} title='OK' color='SeaGreen' size="lg" />) : <button onClick={handleHcDoneClick}>Fait</button>) : "\u00A0"}</div>
                        <div className="hc-cell report"  data-label="Rapport">{today && reservation.hc_done ? "\u000A" : <a href={`https://forms.fillout.com/t/3THorEGZbius?resa=${reservation.id}&pname=${reservation.property.name}`}><FontAwesomeIcon icon={faFilePen} title='Rapport' color='Grey' /></a>}</div>
                    </>
                    : <><div className="hc-cell done" ></div><div className="hc-cell report" ></div><div className="hc-cell" ></div></> }
            </div>
        )
    }

    return (
        <>
            <div class="hc-table">
                {hcList.map((houseclean, idx) => {
                    return <ReservationDiv key={`t${idx}`} reservation={houseclean} data-key={idx} />
                })}
            </div>
            <input type="button" value="Envoi SMS" onClick={displaySmsPopup} />
            <SMSPopup
                trigger={smsPopup}
                setTrigger={setSmsPopup}
                formData={smsFormData}
                onChange={handleSmsFormChange}
                onSubmit={handleSmsSendSubmit}
                onNext={handleSmsSendNext}
            />
        </>
    )
}

export default HousecleanTable;