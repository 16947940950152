import { getReservationToBeValidated } from "../utils/RestLocem"

import ChekinTable from "../components/checkin/ChekinTable";

function CheckinToBeValidated() {

    const loadNextCheckout = async () => {
        return await getReservationToBeValidated()
    }

    return (
        <ChekinTable loadReservations={() => { return loadNextCheckout() }} />
    );
}

export default CheckinToBeValidated;