import { useState, useEffect } from 'react'
import axios from "../api/axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAirbnb } from "@fortawesome/free-brands-svg-icons"
import { format } from 'date-fns'

const PrivateTodo = () => {

    const mult = [10, 25, 50, 100, 150]
    const addition =  [10]

    const generateData = (iIndex,iA, iB) => {
        let data = new Array(250).fill({});
        let a = iA;
        let b = iB;
    
        for (let i = iIndex; i <= 150; i++) {
          data[i] = { i, a, b }
          
          a = a * 1.08;
          if (mult.includes(i+1)) {
            a = a * 2
          }
          b = b * 1.2;
        }

        for (let i = iIndex; i <= 150; i++) {
            // if (i == iIndex) {
            //     continue
            // }
            console.log(i, data[i])
            const c = data[i+1].a - data[i].a
            const d = c / data[i].b
            const line = { ...data[i], c, d}
            data[i] = line

            // console.log(data[i])
          }
    
          console.log(data)
        return data
      };

      const produits = [
        // 6
        // generateData(1, 6, 4)
        // generateData(10, 31, 18)
        generateData(15, 46*3*5, 43),

        // 275
        generateData(1, 320*3, 165),

        // 13,8k
        generateData(1, 12800, 8250),

        // 688k
        generateData(1, 512000, 413000)

        // generateData(136, 199000000000000,404000000000000),
        // generateData(111, 1160000000000000,212000000000000),
        // generateData(100, 20000000000000000,1430000000000000),
        // generateData(77, 3410000000000000,1080000000000000)
        // generateData(115, 2540000000000000000,1100000000000000000),
    ]

        const produitsFirstIndex = [50, 50, 35, 21]


    useEffect(() => {

        // fetchTodo()

    },[])

        
  const  formatNumber = (num) => {
    if (!num){return "-"}
    const units = ['', 'k', 'M', 'B', 'T', 'aa', 'ab', 'ac', 'ad'];
    let unitIndex = 0;
    while (Math.abs(num) >= 1000 && unitIndex < units.length - 1) {
      num /= 1000;
      unitIndex++;
    }
    return num.toFixed(2) + units[unitIndex];
  };

    return (
        <article>
            
                    <table><tr>
                    { produits.map((lignes, idxP) => (
                        <td><table>
                            <tbody>
                                {lignes.filter(ligne => (ligne.i >= produitsFirstIndex[idxP] && ligne.i <= produitsFirstIndex[idxP] + 10)).map((ligne, idx) =>
                                    <tr key={idx}>
                                        <td>{ligne.i}</td>
                                        <td>{formatNumber(ligne.a)}</td>
                                        <td>{formatNumber(ligne.b)}</td>
                                        <td>{formatNumber(ligne.c)}</td>
                                        <td>{(ligne.d *100).toFixed(1)}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table></td>
                    ))}
                    </tr></table>
                    
        </article>
    )
}

export default PrivateTodo